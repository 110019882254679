





















import Vue from 'vue'
import { ApiResponse, Project, Sale, Document, RequisitionItem } from '@/interfaces'

export default Vue.extend({
  props: {
    parentObject: {
      type: Object as () => Project | Sale | RequisitionItem,
      required: false,
      default: () => {
        return {
          id: null,
        }
      },
    },
    docToUpdate: {
      type: Object as () => Document,
      required: false,
      default: null,
    },
    parentType: {
      type: String,
      required: false,
      default: null,
    },
    objectType: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      percent: 0,
      showProgressModal: false,
      filesToProcess: 0,
      processedFiles: 0,
      status: 'success',
      modalMessage: '',
      filesToUpload: [] as File[],
      fileIndexToUpload: null as null | number,
    }
  },
  watch: {
    fileIndexToUpload(newVal) {
      if (newVal !== null && this.filesToUpload.length > 0 && newVal <= this.filesToUpload.length) {
        this.uploadFile(this.filesToUpload[newVal])
      }
    },
    filesToUpload(newVal) {
      this.filesToProcess = newVal.length
    },
  },
  methods: {
    addFile() {
      document.getElementById('inputDocument')?.click()
    },
    resetProgress() {
      this.filesToProcess = 0
      this.processedFiles = 0
      this.percent = 0
      this.status = 'success'
    },
    processFiles(files: File[] | FileList) {
      this.showProgressModal = true
      this.modalMessage = 'Vérification du nom des fichiers en cours...'

      this.modalMessage = 'Envoi des fichiers en cours...'
      this.processedFiles = 0

      const arr = Array.from(files)

      let filesToUpload = [] as File[]
      for (let i = 0; i < arr.length; i++) {
        const file = arr[i]
        filesToUpload.push(file)
      }

      this.filesToUpload = filesToUpload
      this.fileIndexToUpload = 0
    },
    dropFile(event: DragEvent) {
      this.resetProgress()
      let droppedFiles = (event.dataTransfer as DataTransfer).files
      if (!droppedFiles) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
        })
        return
      }

      const droppedFilesArray = [...droppedFiles]

      this.filesToProcess = droppedFilesArray.length

      if (this.filesToProcess > 1) {
        this.showProgressModal = true
      }

      this.modalMessage = 'Envoi des fichiers en cours...'

      this.filesToUpload = droppedFilesArray
      this.fileIndexToUpload = 0
    },
    updateInputDocument(event: Event) {
      const inputDocuments = (event.target as HTMLInputElement).files
      if (!inputDocuments) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
        })
        return
      }

      let filesToUpload = [] as File[]
      Array.from(inputDocuments).forEach((doc) => {
        filesToUpload.push(doc)
      })
      this.filesToUpload = filesToUpload
      this.fileIndexToUpload = 0
    },
    uploadFile(file: File) {
      if (!file) return
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener(
        'load',
        () => {
          let dataurl = ''
          if (reader.result) {
            dataurl = reader.result.toString().toString().split(',')[1]
            this.sendData(file, dataurl)
          }
        },
        false
      )
    },
    sendData(file: File, dataurl: string) {
      const filename = file.name
      const filenameSplit = filename.split('-')
      const itemId = filenameSplit[0] ? `#${filenameSplit[0]}` : ''

      const data = {
        filename: filename,
        data: dataurl,
        docToUpdate: this.docToUpdate?.id?.toString(),
      }

      this.busy = true

      this.$api
        .post(`/${this.objectType}/${this.parentObject.id}/document`, data)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Fichier ajouté avec succès !',
          })

          // Increasing index to upload
          if (this.fileIndexToUpload == this.filesToUpload.length - 1) {
            this.fileIndexToUpload = null
            this.$emit('refreshDocuments', apiResponse.data)
            this.$emit('refreshItem')
          } else {
            if (this.fileIndexToUpload !== null) {
              this.fileIndexToUpload++
            }
          }
        })
        .catch((error) => {
          if (error.response?.status === 413) {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Erreur : le fichier que vous souhaitez téléverser est trop lourd.',
            })
          } else {
            let message = 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.'
            if (error.response.data.message == 'Requisition item not found.') {
              message = `Lot de réquisition ${itemId} non trouvé.`
            }

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message,
            })
          }
        })
        .finally(() => {
          this.busy = false
          this.processedFiles += 1
          this.percent = Math.round((this.processedFiles / this.filesToProcess) * 100)

          if (this.processedFiles == this.filesToProcess) {
            this.status = 'success'
            setTimeout(() => {
              this.showProgressModal = false
            }, 1000)

            this.$emit('refreshSaleItems')
          }
        })
    },
  },
})
