






















































































































import Vue from 'vue'
import { ApiResponse, Document, Contact, FormContact } from '@/interfaces'
import SendMailForm from '@/components/SendMailForm.vue'
import EditDocumentForm from '@/components/EditDocumentForm.vue'

interface SendMailData {
  contacts: FormContact[]
  customText?: string
  structure?: number | null
  chosenModel?: number | null
}

export default Vue.extend({
  components: { SendMailForm, EditDocumentForm },
  props: {
    documents: {
      type: Array,
      required: false,
      default: [] as Document[],
    },
    parentType: {
      type: String,
      required: true,
      default: '',
    },
    defaultSendContact: {
      type: Object as () => Contact,
      required: false,
      default: null,
    },
    debiteurSendContact: {
      type: Object as () => Contact,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
    parentId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      showSendDocumentModal: false,
      sendDocumentType: '',
      documentToSend: {} as Document,
      sendMailData: {} as SendMailData,
      documentToEdit: {} as Document | null,
      documentIndex: -1,
      showEditDocumentModal: false,
      urlParentType: '',
    }
  },
  watch: {
    activeTab(newVal) {
      if (newVal === 'documents' && this.documents.length === 0) {
        this.urlParentType = this.parentType
        if (this.parentType === 'requisitionItem') {
          this.urlParentType = 'requisitionitems'
        }
        this.getDocuments()
      }
    },
    parentType(newVal) {
      this.urlParentType = newVal
      if (newVal === 'requisitionItem') {
        this.urlParentType = 'requisitionitems'
      }
    },
  },
  mounted() {
    if (this.activeTab && this.activeTab === 'documents' && this.parentType && this.parentId) {
      this.urlParentType = this.parentType
      if (this.parentType === 'requisitionItem') {
        this.urlParentType = 'requisitionitems'
      }
      this.getDocuments()
    }
  },
  methods: {
    download(format: string, token: string) {
      let url = ''

      if (format == 'pdf') {
        url = `${process.env.VUE_APP_API_URL}/document/${token}/download?contentd=inline`
      } else {
        url = `${process.env.VUE_APP_API_URL}/document/${token}/download`
      }

      window.open(url, '_blank')
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short' }).format(date)
      } else {
        return '-'
      }
    },
    formatSize(size: string): string {
      const s = parseFloat(size)
      const kb = s * 1000

      return `${kb} ko`
    },
    getFormat(format: string): string {
      switch (format) {
        case 'docx':
          return 'DOCX'

        case 'pdf':
          return 'PDF'

        default:
          return 'Autre'
      }
    },
    getType(document: Document): string {
      return document.labelType ?? ''
    },
    getStructureName(document: Document) {
      if (document.structure) {
        return ` (${document.structure.name})` ?? ''
      }
      return ''
    },
    toggleSign(document: Document) {
      this.$api
        .post(`/document/${document.id}/togglesign`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          // Updating document
          document.signed = apiResponse.data.document.signed
          if (document.type === 'vente') {
            this.$emit('updateRequisitionItems', apiResponse.data.items)
          }
        })
        .catch(() => {
          // Reverting status
          document.signed = !document.signed

          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
    },
    deleteDocument(document: Document) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer ce document ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/document/${document.id}/${this.parentType}`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.$emit('refreshDocuments', apiResponse.data)
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
      })
    },
    openSendDocument(document: Document, type: string) {
      this.showSendDocumentModal = true
      this.documentToSend = document
      this.sendDocumentType = type
    },
    closeSendDocument() {
      this.showSendDocumentModal = false
      this.documentToSend = {}
      this.sendDocumentType = ''
    },
    submit(sendMailData: SendMailData) {
      this.sendMailData = sendMailData
      this.sendDocument(this.documentToSend)
    },
    sendToSign(document: Document) {
      let message = `Vous vous apprêtez à envoyer un document pour signature à ${this.defaultSendContact.formName}. Êtes-vous sûr(e) ?`
      this.$confirm(message, 'Confirmation', {
        confirmButtonText: 'Envoyer',
        cancelButtonText: 'Annuler',
        type: 'warning',
      }).then(() => {
        this.$api
          .get(`/yousign/${document.accessToken}/to-sign`, {
            params: {
              contact: this.defaultSendContact?.id,
            },
          })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'La demande de signature du document a bien été envoyée.',
            })
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      })
    },
    sendDocument(document: Document) {
      let successMessage = 'Le document '
      if (document.title) {
        successMessage += document.title + ' '
      }
      successMessage += 'a été envoyé.'

      const loading = this.$loading({
        target: '#container',
        text: 'Chargement des données...',
      })

      const data = {
        ...this.sendMailData,
        type: this.parentType,
      }

      let success = false
      this.$api
        .post(`/document/${document.id}/send`, data)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Document envoyé !',
            message: successMessage,
          })
          success = true
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          loading.close()
          if (success) {
            this.closeSendDocument()
          }
        })
    },
    convertToPdf(document: Document) {
      this.$api
        .post(`/document/${document.id}/pdf`, {
          page: this.parentType === 'contact' ? 'contactDocuments' : null,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Opération réalisée avec succès !',
          })

          this.$emit('refreshDocuments', apiResponse.data)
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
    },
    updateDoc(document: Document) {
      this.$emit('updateDoc', document)
    },
    displayForSend(document: Document) {
      return document.format === 'pdf'
    },
    displayForSign(document: Document) {
      return document.format === 'pdf' && document.contact?.id
    },
    openEditDocument(document: Document, index: number) {
      this.showEditDocumentModal = true
      this.documentToEdit = document
      this.documentIndex = index
    },
    closeEditDocument() {
      this.showEditDocumentModal = false
      this.documentToEdit = null
      this.documentIndex = -1
    },
    submitEditDocument(document: Document) {
      this.busy = true
      this.$api
        .put(`/document/${document.id}`, {
          type: document.type ?? '',
          title: document.title ?? '',
          reference: document.reference ?? '',
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.documents.splice(this.documentIndex, 1, apiResponse.data)
          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Document modifié avec succès !',
          })

          this.$emit('refreshItem')
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          this.closeEditDocument()
        })
    },
    getDocuments() {
      if (this.parentId) {
        this.$api(`/${this.urlParentType}/${this.parentId}/documents`).then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$emit('refreshDocuments', apiResponse.data)
        })
      }
    },
  },
})
